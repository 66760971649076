@import "index";

.bg-dark {
    @extend .text-white
}

.bg-black-opacity {
    background-color: rgba(0, 0, 0, 0.5);
}

.text-section-container {
    white-space: pre-wrap;

    @media (max-width: 767px) {
        text-align: start;
    }

    @media (min-width:768px) {
        text-align: justify;
    }
}